<template>
  <div class="app" v-if="route.path != '/login'">
    <Header ref="headerRef" @showResolve="resolveClick"></Header>
    <div class="container">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in" appear>
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
    <Footer @showResolve="resolveClick" @showMessage="message_footer = true" @footerRouterClick="footerRouterClick()"></Footer>
    <div class="sidebar">
      <ul>
        <li class="side_top" @click="sideTopClick()">
          <img src="@/assets/index/侧边-置顶.png" alt="">
        </li>
        <li class="side_weChat">
          <img src="@/assets/index/侧边-微信.png" alt="">
          <div class="move move_weChat">
            <img src="@/assets/index/weChatBtns.png" alt="">
            <p>扫描二维码添加<br />专属产品顾问</p>
          </div>
        </li>
        <li class="side_phone">
          <img src="@/assets/index/侧边-电话.png" alt="">
          <div class="move move_phone">
            <p>186-0127-1236</p>
            <p>周一至周日 9:00-18:00</p>
          </div>
        </li>
        <li class="side_message">
          <img src="@/assets/index/侧边-消息.png" alt="" @click="message_click(!messageShow)">
          <div class="move move_message" v-if="messageShow">
            <Message @hideMessage="message_click(false)"></Message>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer_message" v-if="message_footer">
      <Message @hideMessage="message_footer = false"></Message>
    </div>
    <div class="resolve">
      <Resolve ref="resolveRef"></Resolve>
    </div>
  </div>
  <div class="app" v-if="route.path == '/login'">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineAsyncComponent } from 'vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useRoute, useRouter } from 'vue-router';
import Resolve from './components/Resolve.vue';
const Message = defineAsyncComponent(() => import('@/components/Message.vue'))
const route = useRoute();
const router = useRouter();
let messageShow = ref(false)
onMounted(() => {
  const side_top = document.querySelector('.side_top')
  window.addEventListener('scroll', () => {
    if (window.scrollY > 1000) {
      side_top.style.display = 'block'
    } else {
      side_top.style.display = 'none'
    }
  })
})
// 监听路由变化，切换页面时滚动到顶部
watch(
  () => route.path,
  () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
)
// 侧边-置顶
const sideTopClick = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
// 显示留言板
const message_click = (val) => {
  messageShow.value = val
}
const message_footer = ref(false)
// 底部跳转产品页默认头部显示控制
const headerRef = ref(null)
const footerRouterClick = () => {
  sessionStorage.setItem('banner_click_active', '1')
  headerRef.value.banner_click_active = 1
}
// 解决方案弹窗
const resolveRef = ref(null)
const resolveClick = () => {
  resolveRef.value.isShowliuyanban2 = true
}
</script>

<style lang="less">
html,
body,
div,
p,
span,
img,
a,
ul,
li,
tr,
td,
th,
video,
dl,
dt {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

.app {
  width: 100vw;
  height: 100vh;

  .container {
    min-height: calc(100% - 75px);
  }
}

.sidebar {
  position: fixed;
  right: 0;
  bottom: 200px;

  ul {
    li {
      width: 100px;
      height: 100px;
      cursor: pointer;
      position: relative;
      z-index: 999;

      img {
        width: 100%;
        height: 100%;
      }

      .move {
        width: 150px;
        position: absolute;
        left: -150px;
        top: 0px;
        background: #fff;
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }

      .move_weChat {
        display: none;
        height: 150px;
        padding: 10px;
        box-sizing: border-box;

        img {
          width: 100px;
          height: 100px;
          display: block;
          margin: 0 auto;
        }

        p {
          color: #3BC694;
          font-size: 12px;
          text-align: center;
        }
      }

      .move_message {
        width: 240px;
        top: -100px;
        left: -260px;
        // height: 380px;
      }

      .move_phone {
        display: none;
        left: -210px;
        width: 200px;
        height: 90px;
        text-align: center;
        font-size: 16px;
        color: #3BC694;
        padding: 20px 0;
        box-sizing: border-box;
      }

      .move_phone::after,
      .move_weChat::after {
        content: '';
        position: absolute;
        top: 30%;
        right: -10px;
        /* 调整三角形的位置 */
        transform: translateY(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        /* 左上 右上 左下 右下 */
      }

    }

    .side_phone:hover,
    .side_weChat:hover {
      .move {
        display: block;
      }
    }

    .side_message {
      z-index: 55;
    }

  }

  .side_top {
    display: none;
  }
}

.footer_message {
  position: fixed;
  left: 100px;
  bottom: 50px;
}
</style>
