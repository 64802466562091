<template>
    <div class="header" :class="{ 'spread_banner': spread_banner }" @mouseleave="banner_mouseenter(-1)">
        <img class="logo" v-if="!spread_banner" src="@/assets/index/logo_swiper1.jpg" alt="" @click="routerGo('/', 0)">
        <img class="logo" v-else src="@/assets/index/logo_swiper2.jpg" alt="" @click="routerGo('/', 0)">
        <ul class="banner_list">
            <li v-for="(item, index) in banner_list" :key="index"
                :class="{ 'banner_click_active': banner_click_active == index, 'banner_mouseenter_active': banner_mouseenter_active == index }"
                @click="banner_click(index)" @mouseenter="banner_mouseenter(index)"
                @mouseleave="banner_mouseenter(index != 1 && index != 2 && index != 3 ? -1 : index)">
                <p>{{ item.name }}</p>
                <el-icon v-if="item.spread" color="#fff" class="spread_icon">
                    <ArrowDownBold />
                </el-icon>
            </li>
        </ul>
        <!-- <p class="login" @click="openLogin()">
            <span>登陆</span>/<span>注册</span>
        </p> -->
        <p class="tryout" @click="openLogin()">
            产品试用
        </p>
        <div class="banner_content banner_content1" v-if="spread_banner && banner_mouseenter_active == 1">
            <div class="banner_content_top">
                <ul>
                    <li>
                        <p class="title">{{ spread_banner_list1[0].title }}</p>
                        <p class="name" @click="routerGo(spread_banner_list1[0].list[0].path, 1)">{{
                            spread_banner_list1[0].list[0].name }}</p>
                    </li>
                    <li>
                        <p class="title">{{ spread_banner_list1[1].title }}</p>
                        <p class="name" @click="routerGo(spread_banner_list1[1].list[0].path, 1)">{{
                            spread_banner_list1[1].list[0].name }}</p>
                    </li>
                    <li>
                        <p class="title">{{ spread_banner_list1[2].title }}</p>
                        <p class="name" @click="routerGo(spread_banner_list1[2].list[0].path, 1)">{{
                            spread_banner_list1[2].list[0].name }}</p>
                    </li>
                </ul>
            </div>
            <div class="banner_content_bottom">
                <div class="banner_content_left">
                    <p class="title">{{ spread_banner_list1[3].title }}</p>
                    <ul>
                        <li class="name" @click="routerGo(spread_banner_list1[3].list[index].path, 1)"
                            v-for="(item, index) in spread_banner_list1[3].list">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
                <div class="banner_content_right">
                    <p class="title">{{ spread_banner_list1[4].title }}</p>
                    <ul>
                        <li class="name" @click="routerGo(spread_banner_list1[4].list[index].path, 1)"
                            v-for="(item, index) in spread_banner_list1[4].list">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="banner_content banner_content2" v-if="spread_banner && banner_mouseenter_active == 2">
            <!-- <div class="banner_content_top">
                <p class="title">{{ spread_banner_list2[0].title }}</p>
                <ul>
                    <li v-for="(item, index) in spread_banner_list2[0].list" class="name">{{ item.name }}</li>
                </ul>
            </div>
            <div class="banner_content_top">
                <p class="title">{{ spread_banner_list2[1].title }}</p>
                <ul>
                    <li v-for="(item, index) in spread_banner_list2[1].list" class="name">{{ item.name }}</li>
                </ul>
            </div>
            <div class="banner_content_bottom">
                <ul>
                    <li class="title">{{ spread_banner_list2[2].title }}</li>
                    <li v-for="(item, index) in spread_banner_list2[2].list" class="name">{{ item.name }}</li>
                </ul>
                <ul>
                    <li class="title">{{ spread_banner_list2[3].title }}</li>
                    <li v-for="(item, index) in spread_banner_list2[3].list" class="name">{{ item.name }}</li>
                </ul>
                <ul>
                    <li class="title">{{ spread_banner_list2[4].title }}</li>
                    <li v-for="(item, index) in spread_banner_list2[4].list" class="name">{{ item.name }}</li>
                </ul>
                <ul>
                    <li class="title">{{ spread_banner_list2[5].title }}</li>
                    <li v-for="(item, index) in spread_banner_list2[5].list" class="name">{{ item.name }}</li>
                </ul>
            </div> -->
            <div class="box">
                <ul v-for="(i, index) in spread_banner_list2">
                    <li class="title">{{ i.title }}</li>
                    <li v-for="(item, index) in i.list" class="name" @click="showResolvefn()">{{ item.name }}</li>
                </ul>
            </div>
        </div>
        <div class="banner_content banner_content3" v-if="spread_banner && banner_mouseenter_active == 3">
            <ul v-for="(item, index) in spread_banner_list3">
                <li class="title">{{ item.title }}</li>
                <li class="name" v-for="i in item.list" @click="routerGo('/case', 3)">{{ i.name }}</li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineExpose, watch } from 'vue';
import { ArrowDownBold } from '@element-plus/icons-vue'
import { ElIcon } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const banner_click_active = ref(0)
const banner_mouseenter_active = ref(-1)
const banner_list = reactive([
    { name: '首页', path: '/index', spread: false },
    { name: '产品服务', path: '/product/guantian', spread: true },
    { name: '解决方案', path: '/-', spread: true },
    { name: '成功案例', path: '/case', spread: true },
    { name: '新闻动态', path: '/news', spread: false },
    { name: '关于我们', path: '/about', spread: false }
])
const banner_click = (index) => {
    if (banner_list[index].path == '/-') return
    router.push(banner_list[index].path)
    sessionStorage.setItem('banner_click_active', index)
    banner_click_active.value = index
    //页面默认显示banner时，避免在点击banner时，导致banner消失
    if (route.path == banner_list[index].path && window.scrollY > 200) {
        spread_banner.value = true
    } else {
        spread_banner.value = false
    }
}
const banner_mouseenter = (index) => {
    banner_mouseenter_active.value = index
}
const spread_banner = ref(false) //展开banner
onMounted(() => {
    // 默认选中
    if (sessionStorage.getItem('banner_click_active')) {
        banner_click_active.value = sessionStorage.getItem('banner_click_active')
    }
    const header_dom = document.querySelector('.header')
    header_dom.addEventListener('mouseleave', () => {
        pageScroll()
    }) //移出可展开banner时
    const spread_banner_dom = document.querySelectorAll('.banner_list li')
    spread_banner_dom.forEach(item => {
        item.addEventListener('mouseenter', () => {
            if (banner_mouseenter_active.value == 1 || banner_mouseenter_active.value == 2 || banner_mouseenter_active.value == 3) {
                spread_banner.value = true
            } else {
                if (window.scrollY < 200) spread_banner.value = false
            }
        })
    })
    window.addEventListener('scroll', pageScroll)
})
const pageScroll = () => {
    if (banner_mouseenter_active.value == 1 || banner_mouseenter_active.value == 2 || banner_mouseenter_active.value == 3) return
    if (window.scrollY > 200) {
        spread_banner.value = true
    } else {
        spread_banner.value = false
    }
}
const spread_banner_list1 = reactive([
    {
        title: '观田®',
        list: [
            {
                name: '地块级精准种植服务平台',
                path: '/product/guantian'
            }
        ]
    },
    {
        title: '曦农®',
        list: [
            {
                name: '区域农情遥感监测与信息服务平台',
                path: '/product/xinong'
            }
        ]
    },
    {
        title: '新农业家',
        list: [
            {
                name: '综合农业服务平台',
                path: '/product/home'
            }
        ]
    },
    {
        title: '芯农伽®智能终端应用产品',
        list: [
            {
                name: '智慧农业无人值守机场',
                path: '/product/uav'
            },
            {
                name: '多源融合光谱监测站',
                path: '/product/guangpu'
            },
            {
                name: '智能水肥一体化',
                path: '/product/manure'
            },
            {
                name: '智能巡田机器人（移动式量子点光谱）',
                path: '/product/guangpu'
            },
            {
                name: '智能监测设备',
                path: '/product/device'
            }
        ]
    },
    {
        title: '遥观®卫星遥感监测',
        list: [
            {
                name: '作物识别和面积提取',
                path: '/product/yaoguan'
            },
            {
                name: '作物成熟度监测',
                path: '/product/yaoguan'
            },
            {
                name: '作物长势遥感监测',
                path: '/product/yaoguan'
            },
            {
                name: '作物产量预测',
                path: '/product/yaoguan'
            },
            {
                name: '叶绿素含量监测',
                path: '/product/yaoguan'
            },
            {
                name: '土壤墒情监测',
                path: '/product/yaoguan'
            },
            {
                name: '植株氮含量监测',
                path: '/product/yaoguan'
            },
            {
                name: '土壤养分监测',
                path: '/product/yaoguan'
            }
        ]
    }
])
const spread_banner_list2 = reactive([
    {
        title: '粮食安全',
        list: [
            {
                name: '大田农业生产监管',
                path: '/'
            },
            {
                name: '高标准农田建设',
                path: '/'
            },
            {
                name: '非农非粮化监管',
                path: '/'
            },
            {
                name: '农产品溯源',
                path: '/'
            },
            {
                name: '单产提升服务',
                path: '/'
            },
            {
                name: '精准种植',
                path: '/'
            },
            {
                name: '应急灾害',
                path: '/'
            },
        ]
    },
    {
        title: '农业产业现代化',
        list: [
            {
                name: '设施大棚智能化设施化',
                path: '/'
            },
            {
                name: '无人农场',
                path: '/'
            },
            {
                name: '数字农业',
                path: '/'
            },
            {
                name: '智慧农业',
                path: '/'
            },
            {
                name: '精准农业',
                path: '/'
            },
            {
                name: '农业产业园规划',
                path: '/'
            },
        ]
    },
    {
        title: '乡村振兴',
        list: [
            {
                name: '数字乡村',
                path: '/'
            },
            {
                name: '人才振兴',
                path: '/'
            },
            {
                name: '农业农村服务平台',
                path: '/'
            },
            {
                name: '新职业农民培训',
                path: '/'
            }
        ]
    },
    {
        title: '农业保险与金融',
        list: [
            {
                name: '农业信贷风控',
                path: '/'
            },
            {
                name: '农业保险数字化',
                path: '/'
            },
            {
                name: '农业保险定损',
                path: '/'
            },
        ]
    },
    {
        title: '特色产业振兴',
        list: [
            {
                name: '梅州柚',
                path: '/'
            },
            {
                name: '常山油茶',
                path: '/'
            },
            {
                name: '农产品价格监测',
                path: '/'
            },
            {
                name: '农业产业园规划升级',
                path: '/'
            },
            {
                name: '种植加工农业产业升级',
                path: '/'
            }
        ]
    },
    {
        title: '数据应用产品',
        list: [
            {
                name: '气象大数据',
                path: '/'
            },
            {
                name: '水环境',
                path: '/'
            },
            {
                name: '大气环境',
                path: '/'
            },
            {
                name: '农业遥感',
                path: '/'
            },
            {
                name: '农业碳汇',
                path: '/'
            }
            ,
            {
                name: '环境质量评价',
                path: '/'
            }
        ]
    }
])
const spread_banner_list3 = reactive([
    {
        title: '政府服务案例',
        list: [
            {
                name: '江西省某市国家现代农业产业园',
                path: '/'
            },
            {
                name: '内蒙省某市松山区种粮一体化',
                path: '/'
            },
            {
                name: '某地区油茶产业大脑',
                path: '/'
            },
            {
                name: '某市供销社服务三农大数据平台',
                path: '/'
            },
            {
                name: '某地区智慧气象为农服务平台',
                path: '/'
            },
        ]
    },
    {
        title: '企业服务案例',
        list: [
            {
                name: '广州佛山高明区万亩丝亩米产业园',
                path: '/'
            },
            {
                name: '呼伦贝尔智慧农业示范区监测平台',
                path: '/'
            },
            {
                name: '海南农垦“数字化智慧茶园”平台',
                path: '/'
            },
            {
                name: '隆平黄埔研究院大吉沙岛数字农场',
                path: '/'
            },
            {
                name: '江西铭宸智慧农业科技产业园',
                path: '/'
            },
            {
                name: '屈原管理区水稻精准种植平台',
                path: '/'
            },
            {
                name: '关山农业服务平台',
                path: '/'
            },
        ]
    },
    {
        title: '其它服务案例',
        list: [
            {
                name: '鲜食玉米+精准农业+三产融合',
                path: '/'
            },
            {
                name: '华联商城助力三产销售',
                path: '/'
            },
            {
                name: '深泽县智慧农村建设',
                path: '/'
            },
        ]
    }
])
const routerGo = (path, index) => {
    router.push(path)
    //页面默认显示banner时，避免在点击banner时，导致banner消失
    if (route.path == path && window.scrollY > 200) {
        spread_banner.value = true
    } else {
        spread_banner.value = false
    }
    banner_click_active.value = index
    banner_mouseenter_active.value = -1
    sessionStorage.setItem('banner_click_active', index)
}
const openLogin = () => {
    window.open('https://www.sino-eco.com/sinoeco_home/#/login', '_blank')
}
// 解决方案弹起
const emit = defineEmits(['showResolve'])
const showResolvefn = () => {
    emit('showResolve')
    spread_banner.value = false
    banner_mouseenter_active.value = -1
}

defineExpose({
    banner_click_active
})
// 监听路由变化，切换页面时滚动到顶部
watch(
    () => route.path,
    () => {
        if (route.path.indexOf("/product") != -1) {
            banner_click_active.value = 1
        } else if (route.path.indexOf("/news") != -1) {
            banner_click_active.value = 4
        }
    }
)
</script>
<style scoped lang='less'>
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        width: 162px;
        height: 40px;
        cursor: pointer;
    }

    .banner_list {
        display: flex;
        height: 100%;
        align-items: center;
        margin: 0 100px;

        li {
            height: 70%;
            display: flex;
            align-items: center;
            margin: 0 10px;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 10px;

            .spread_icon {
                margin-left: 2px;
                font-size: 14px;
            }

            p {
                color: #fff;
                font-size: 16px;
            }
        }
    }


    .login {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        margin-right: 20px;

        span:hover {
            color: #F7A700;
        }
    }

    .tryout {
        width: 95px;
        height: 34px;
        background: #F7A700;
        border-radius: 5px;
        color: #FFFFFF;
        cursor: pointer;
        text-align: center;
        line-height: 34px;
        font-size: 15px;
    }

    .banner_content {
        width: 100%;
        height: 497px;
        background: #FFFFFF;
        position: absolute;
        top: 73px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 400px;
        z-index: 999;

        .title {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 20px;
            color: #353535;
        }

        .name {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
        }

        p {
            line-height: 35px;
        }

        .name:hover {
            color: #3BC694;
            cursor: pointer;
        }
    }

    .banner_content1 {
        .banner_content_top {
            width: 100%;
            margin-bottom: 40px;

            ul {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }

        .banner_content_bottom {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .banner_content_left,
            .banner_content_right {
                width: 50%;

                ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    li {
                        width: 50%;
                        line-height: 50px;

                        &:hover {
                            color: #3BC694;
                            cursor: pointer;
                        }
                    }
                }
            }

            .banner_content_right {
                width: 40%;

            }

        }
    }

    .banner_content2 {
        // .banner_content_top {
        //     width: 100%;
        //     margin-bottom: 30px;

        //     .title {
        //         margin-bottom: 15px;
        //     }

        //     ul {
        //         width: 100%;
        //         display: flex;

        //         li {
        //             min-width: 160px;
        //         }

        //         li:nth-child(1) {
        //             min-width: 200px;
        //         }
        //     }
        // }

        // .banner_content_bottom {
        //     display: flex;
        //     width: 100%;
        //     justify-content: space-between;

        //     ul {
        //         display: flex;
        //         flex-wrap: wrap;
        //         flex-direction: column;
        //         // justify-content: space-between;

        //         li {
        //             line-height: 35px;

        //             .name:hover {
        //                 color: #3BC694;
        //                 cursor: pointer;
        //             }
        //         }

        //     }
        // }
        .box {
            height: 100%;
            display: flex;

            ul {
                margin: 30px;

                li {
                    text-wrap: nowrap;
                    margin-top: 20px;
                }
            }
        }

    }

    .banner_content3 {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;

        ul {
            margin-top: 30px;

            li {
                line-height: 50px;
            }
        }
    }
}

.spread_banner {
    background-color: #fff;
    border-bottom: 1px solid #cacaca;
    transition: 0.1s;

    .banner_list {
        li {
            p {
                color: #000;
            }

            .spread_icon {
                color: #000;
            }
        }
    }

    .login {
        color: #000;
    }
}

.banner_click_active {
    border-bottom: 2px solid #3BC694;
}

.banner_mouseenter_active {
    p {
        color: #3BC694 !important;
    }

    .spread_icon {
        transform: rotate(180deg);
        color: #3BC694 !important;
        transition: 0.2s;
    }
}
</style>