import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: '',
        redirect: '/index',
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue')
    },
    {
        path: '/product',
        name: 'product',
        redirect: '/product/guantian',
        children: [
            {
                path: 'guantian',
                name: 'guantian',
                component: () => import('../views/product/guantian.vue')
            },
            {
                path: 'xinong',
                name: 'xinong',
                component: () => import('../views/product/xinong.vue')
            },
            {
                path: 'uav',
                name: 'uav',
                component: () => import('../views/product/uav.vue')
            },
            {
                path: 'yaoguan',
                name: 'yaoguan',
                component: () => import('../views/product/yaoguan.vue')
            },
            {
                path: 'guangpu',
                name: 'guangpu',
                component: () => import('../views/product/guangpu.vue')
            },
            {
                path: 'device',
                name: 'device',
                component: () => import('../views/product/device.vue')
            },
            {
                path: 'manure',
                name: 'manure',
                component: () => import('../views/product/manure.vue')
            },
            {
                path: 'home',
                name: 'home',
                component: () => import('../views/product/home.vue')
            },
        ]
    },
    {
        path: '/case',
        name: 'case',
        component: () => import('../views/case.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/news.vue')
    },
    {
        path: '/promote',
        name: 'promote',
        component: () => import('../views/promote.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login.vue')
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

//路由守卫
router.beforeEach((to, from, next) => {
    next();
});

export default router
