<template>
    <div class="footer">
        <div class="footer_content">
            <ul>
                <li>
                    <img src="@/assets/index/logo.png" alt="">
                </li>
                <li>电话：186-0127-1236</li>
                <li>邮箱：weezhy@sino-eco.com</li>
                <li>地址：北京市朝阳区麦子店街24号<br />农广大厦（农业部北办公区）</li>
                <li @click="showMessagefn()" class="messageli">
                    <img src="@/assets/index/liuyan.png" class="liuyan" alt="">在线留言
                </li>
            </ul>
            <ul class="product">
                <li @click="goRouter('/product/guantian')">产品服务</li>
                <li @click="goRouter('/product/guantian')">观田® - 地块级精准种植服务</li>
                <li @click="goRouter('/product/xinong')">曦农® - 区域级农业服务</li>
                <li @click="goRouter('/product/home')">新农业家 - 综合农业服务</li>
                <li @click="goRouter('/product/yaoguan')">遥观®- 卫星遥感应用产品</li>
                <li @click="goRouter('/product/uav')">芯农伽®-智慧农业无人值守机场</li>
                <li @click="goRouter('/product/guangpu')">芯农伽®-多源融合光谱监测站</li>
                <li @click="goRouter('/product/device')">芯农伽®-智能监测设备</li>
            </ul>
            <ul class="resolve">
                <li v-for='i in ["解决方案", "粮食安全", "农业现代化", "乡村振兴", "农业保险/金融服务", "特色产业振兴", "农业产业规划", "数据应用产品"]' :key="i"
                    @click="showResolvefn()">
                    {{ i }}
                </li>
            </ul>
            <div class="r">
                <p class="phone">咨询热线：186-0127-1236</p>
                <div class="code">
                    <div>
                        <img src="@/assets/index/tuceng24.png" alt="">
                        <p>新农业家小程序</p>
                    </div>
                    <div>
                        <img src="@/assets/index/tuceng23.png" alt="">
                        <p>官方公众号</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="b">
            <img src="@/assets/index/logo_swiper1.jpg" alt="">
            <p>丨 © 中科赛诺（北京）科技有限公司 丨 京ICP备17052418号</p>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter()
const emit = defineEmits(['showMessage', 'footerRouterClick', 'showResolve'])
const showMessagefn = () => {
    emit('showMessage')
}
const goRouter = (path) => {
    emit('footerRouterClick')
    router.push(path)
}
const showResolvefn = () => {
    emit('showResolve')
}
</script>
<style scoped lang='less'>
.footer {
    height: 420px;
    background: #1C352C;
    border-radius: 5px;
    padding: 50px 20%;
    box-sizing: border-box;

    .footer_content {
        display: flex;
        justify-content: space-between;

        img {
            width: 129px;
            height: 31px;
        }

        .liuyan {
            width: 18px;
            height: 16px;
            margin-right: 5px;
        }

        .messageli {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        ul {
            li {
                font-family: PingFang SC;
                font-weight: 300;
                font-size: 14px;
                color: #FFFFFF;
                // line-height: 30px;
                margin-bottom: 10px;
            }

            li:nth-child(1) {
                font-size: 17px;
                margin-bottom: 10px;
            }
        }

        .product,
        .resolve {
            cursor: pointer;
        }

        .product li:hover {
            color: #f7a600;
        }

        .resolve li:hover {
            color: #f7a600;
        }


        .r {
            width: 250px;

            .phone {
                font-family: PingFang SC;
                font-weight: 500;
                font-size: 20px;
                color: #FFFFFF;
                text-wrap: nowrap;
            }

            .code {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;

                div {

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        width: 104px;
                        height: 106px;
                        margin: 10px 0;
                    }

                    p {
                        font-family: PingFang SC;
                        font-weight: 300;
                        font-size: 14px;
                        color: #DEDEDE;
                    }
                }
            }

        }
    }

    .line {
        width: 1200px;
        height: 1px;
        background: #436056;
        margin: 30px 0;
    }

    .b {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 70px;
            height: 18px;
        }

        p {
            font-family: PingFang SC;
            font-weight: 300;
            font-size: 14px;
            color: #FFFFFF;
        }
    }
}
</style>