<template>
    <div class="mask" v-if="isShowliuyanban2" @click="isShowliuyanban2 = false">
    </div>
    <div class="liuyanban2" v-if="isShowliuyanban2">
        <div class="header">立即获取解决方案</div>
        <div class="form">
            <div class="formItem">
                <span>您的称呼：</span>
                <input type="text" placeholder="请输入您的称呼" v-model="liuyanName">
            </div>
            <div class="formItem">
                <span>您的手机号：</span>
                <input type="text" placeholder="请输入您的手机号" v-model="liuyanPhone">
            </div>
        </div>
        <div class="option">
            <div class="submit" @click="liuyanSend">
                立即获取
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive,watch } from 'vue';
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useRoute } from 'vue-router'  
const route = useRoute()
const isShowliuyanban2 = ref(false)
let liuyanName = ref('')
let liuyanPhone = ref('')
const liuyanSend = () => {
    if (!liuyanName.value) {
        ElMessage.warning("请输入您的称呼")
        return
    }
    if (!checkTelephone(liuyanPhone.value)) {
        ElMessage.warning("手机号输入有误")
        return
    }
    axios.post("https://sinosaas.sino-eco.com/guan-tian/farm/messageBoard/add", {
        link: liuyanName.value,
        phone: liuyanPhone.value,
        content: "该用户点击了解决方案，请尽快联系",
        address: "此类型消息无地址"
    }).then((res) => {
        ElMessage.success("发送成功，工作人员将尽快联系您")
        isShowliuyanban2.value = false
        liuyanName.value = ""
        liuyanPhone.value = ""
    })
}
const checkTelephone = (telephone) => {
    var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!reg.test(telephone)) {
        return false;
    } else {
        return true;
    }
}
defineExpose({
    isShowliuyanban2
})

</script>
<style scoped lang='less'>
.mask {
    background: #00000033;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 88;
}

.liuyanban2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: fixed;
    top: calc(0% + 50vh);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    z-index: 99;

    .header {
        background: #2ccc9b;
        text-align: center;
        font-size: 24px;
        line-height: 2.2;
        color: #fff;
    }

    .form {
        display: flex;
        gap: 20px;
        color: #333;
        padding: 20px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #000000;
        line-height: 22px;

        input {
            width: 275px;
            height: 22px;
            border-radius: 4px;
            border: 1px solid #D5D5D5;
            padding: 10px;
        }
    }

    .option {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;

        >div {
            background: #2ccc9b;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 197px;
            height: 52px;
            border-radius: 1000px;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>